export type Article = {
  id: number;
  title: string;
  link: string;
  image?: string;
  publisher: string;
  published_at: string;
  category?: string;
  summary?: string;
  content?: string;
};

export type Articles = Array<Article>;

export type YahooComment = {
  username: string;
  comment: string;
  replied?: number;
  liked?: number;
  disliked?: number;
  published_at: string;
};

export type YahooComments = Array<YahooComment>;

export type YahooArticle = Article & {
  comments?: Array<YahooComment>;
};

export type YahooArticles = Array<YahooArticle>;

export type NewspicksComment = {
  userid: number;
  userjob: string;
  username: string;
  comment: string;
  liked: number;
  published_at: string;
};

export type NewspicksComments = Array<NewspicksComment>;

export type NewspicksArticle = Article & {
  picks?: number;
};

export type NewspicksArticles = Array<NewspicksArticle>;

export type NewsParameter = {
  category?: string;
  publisher?: string;
  keyword?: string;
  query?: string;
};

export const DEFAULT_LENGTH = 20;
export const INIT_COMMENT_LENGTH = 3;
