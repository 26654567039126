import React from "react";

type Props = {
  code: string;
  duration?: string;
  width?: number;
  height?: number;
  className?: string;
};

export default function Chart({
  code,
  duration = "2y",
  width = 755,
  height = 400,
  className,
}: Props) {
  let chartCode: string = code + ".T";
  let int = 9;
  let per = 9;

  switch (code) {
    case "998407.O": //Nikkei225
      chartCode = ".N225";
      break;
    case "998405.T": //TOPIX
      chartCode = ".TOPX";
      break;
    case "": //マザーズ
      chartCode = ".MTHR";
      break;
    case "23337.T": //JASDAQ
      chartCode = ".JSD";
      break;
    case "000001.SS": //上海総合
      chartCode = ".SSEC";
      break;
    default:
      if (code.match(/^\^/)) chartCode = "." + code.substring(1);
      else chartCode = code.match(/\.\w+$/) ? code : code + ".T";
      break;
  }

  switch (duration) {
    case "15m":
      int = 3;
      per = 4;
      break;
    case "1h":
      int = 4;
      per = 4;
      break;
    case "3M":
      int = 5;
      per = 4;
      break;
    case "6M":
      int = 5;
      per = 5;
      break;
    case "1y":
      int = 5;
      per = 8;
      break;
    case "5y":
      int = 7;
      per = 12;
      break;
    case "10y":
      int = 7;
      per = 13;
      break;
    case "2y":
    default:
      int = 6;
      per = 9;
      break;
  }

  const image = `https://www.trkd-asia.com/rakutensec/common/genChart.jsp?sym=${chartCode}&mode=2&int=${int}&per=${per}&top=9&toparg=&bottom=2&style=1&compare=&width=${width}&height=${height}&tpoint=`;

  return (
    <img
      alt={`stock ${code} chart`}
      src={image}
      className={`w-full align-middle p-1 ${className}`}
    />
  );
}
