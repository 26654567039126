import React from "react";

import Icon from "components/Common/Icon";
import config from "config/news";
import { useMqtt } from "libs/api";

type Props = {
  message: Object;
  className?: string;
};
export default function ServiceArticle({ message, className }: Props) {
  const { mqttPublish } = useMqtt(config.mqtt.options);

  const onRefreshClick = () => {
    mqttPublish(config.mqtt.topic, JSON.stringify(message));
  };
  return (
    <a
      className={`fixed z-50 bg-background-300 text-foreground-400 rounded-full flex w-10 h-10 lg:w-12 lg:h-12 text-xl items-center justify-center mt-2 top-24 right-3 ${className}`}
      href="#"
      onClick={onRefreshClick}
    >
      <Icon icon="refresh" />
    </a>
  );
}
