import React from "react";

type Props = {
  image: string;
  className?: string;
};

export default function Profile({ image, className = "top-auto" }: Props) {
  return (
    <div
      className={`absolute w-full h-full bg-center bg-cover ${className}`}
      style={{
        backgroundImage: `url('${image}')`,
      }}
    >
      <span
        id="blackOverlay"
        className="w-full h-full absolute opacity-50 bg-background"
      ></span>
    </div>
  );
}
