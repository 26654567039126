import React from "react";

import Alert from "./Alert";

type Props = {
  message: string;
};

export default function AlertError({ message }: Props) {
  return <Alert color="red" icon="bug" caption="Error!" message={message} />;
}
