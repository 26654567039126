import { useState, useEffect, Dispatch, SetStateAction } from "react";

export { navigate as redirect } from "gatsby";

export function getQuery(): URLSearchParams {
  const q: string = typeof window === "undefined" ? "" : window.location.search;
  return new URLSearchParams(q);
}

export function useQuery(
  param: string
): [string, Dispatch<SetStateAction<string>>] {
  const [query, setQuery] = useState<string>("");

  useEffect(() => {
    const q = getQuery();
    setQuery(q.get(param) ?? "");
  }, []);

  return [query, setQuery];
}

type R = Record<string, string>;

export function useQueries(
  params: Array<string>
): [R, Dispatch<SetStateAction<R>>] {
  const [values, setValues] = useState<R>({});

  useEffect(() => {
    const query = getQuery();
    let v: R = {};
    params.forEach((k) => {
      v[k] = query.get(k) ?? "";
    });

    setValues(v);
  }, []);

  return [values, setValues];
}

export function generateID(prefix: string = ""): string {
  const hash = Math.random().toString(36).slice(-7);
  return `${prefix}-${hash}`;
}
