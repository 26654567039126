import React from "react";
import { YahooComment } from "./types";
import Icon from "components/Common/Icon";

type Props = {
  comment: YahooComment;
  className?: string;
};

export default function Comment({ comment, className }: Props) {
  return (
    <div className={`relative flex flex-row w-full px-2 ${className}`}>
      <div className="lg:w-1/12 items-center flex-shrink-0">
        <div className="w-full mb-2 pb-2 block text-primary-500">
          <span>
            <Icon icon="thumbs-up" />
          </span>
          <span className="px-1">{comment.liked}</span>
        </div>
        <div className="w-full mb-2 pb-2 block text-secondary-500">
          <span>
            <Icon icon="thumbs-down" />
          </span>
          <span className="px-1">{comment.disliked}</span>
        </div>
      </div>
      <div className="flex-grow w-full relative text-foreground-300 bg-background-300 px-3 py-2 ml-2 rounded-xl overflow-ellipsis overflow-hidden">
        <div className="text-foreground-400 text-xs flex flex-col lg:flex-row flex-wrap">
          <span className="pr-4">{comment.username}</span>
          <span className="text-foreground-500">{comment.published_at}</span>
        </div>
        <div
          className="w-full break-words"
          dangerouslySetInnerHTML={{ __html: comment.comment }}
        />
      </div>
    </div>
  );
}
