import React from "react";

import { YahooComment, DEFAULT_LENGTH } from "./types";
import YahooComments from "./YahooComments";
import Loading from "components/Common/Loading";
import AlertError from "components/Alerts/AlertError";
import { useSWRFetch } from "libs/api";

type Props = {
  link: string;
  comment?: number;
  className?: string;
};
export default function ServiceYahooComments({
  link,
  className,
  comment = DEFAULT_LENGTH,
}: Props) {
  const url =
    process.env.REACT_APP_API_PATH_YAHOO_COMMENTS +
    `?link=${encodeURI(link)}&length=${comment}`;
  const { data: comments, isLoading, error, noData } = useSWRFetch(url);

  if (!comment || comment < 1) return <></>;

  if (error)
    return (
      <div className={`flex flex-wrap ${className}`}>
        <div className="w-full p-3">
          <AlertError message={`Could not load comments`} />
        </div>
      </div>
    );
  if (isLoading) return <Loading />;

  if (noData) return <></>;

  if (comments.length > comment) {
    const coms = comments.filter((com: YahooComment, i: number) => i < comment);
    return <YahooComments comments={coms} className={className} />;
  }
  return <YahooComments comments={comments} className={className} />;
}
