import React from "react";
import { RouteComponentProps } from "@reach/router";

type Props = RouteComponentProps & {
  className?: string;
  component: React.ComponentType<any>;
};

export default function Route({
  component: Component,
  path,
  default: d,
  location,
  uri,
  navigate,
  ...rest
}: Props) {
  return <Component {...rest} />;
}
