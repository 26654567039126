import React from "react";
import { YahooComments } from "./types";
import YahooComment from "./YahooComment";

type Props = {
  comments: YahooComments;
  className?: string;
};

export default function Comments({ comments, className }: Props) {
  return (
    <div className={`w-full mb-4 ${className}`}>
      {comments.map((comment, i) => (
        <div key={i} className="w-full p-2">
          <YahooComment key={i} comment={comment} />
        </div>
      ))}
    </div>
  );
}
