import React, { ReactNode } from "react";
import { Link as GatsbyLink } from "gatsby";

type Props = {
  href: string;
  internalHref?: string;
  className?: string;
  children: ReactNode;
  [x: string]: any;
};
export default function Link({
  href,
  internalHref,
  className,
  children,
  ...rest
}: Props) {
  if (internalHref)
    return (
      <GatsbyLink to={internalHref} className={className}>
        {children}
      </GatsbyLink>
    );
  else
    return (
      <a href={href} className={className} {...rest}>
        {children}
      </a>
    );
}
