import React, { useState } from "react";
import CardBackground from "components/Cards/CardBackground";
import Link from "components/Common/Link";
import Icon from "components/Common/Icon";
import Card from "components/Cards/Card";
import CardIcon from "components/Cards/CardIcon";
import CardIcons from "components/Cards/CardIcons";
import CardImage from "components/Cards/CardImage";

export default function ThangisWho() {
  const [liked, setLike] = useState(false);

  return (
    <main className="profile-page">
      <section className="relative block h-500-px">
        <CardBackground
          className="top-auto bg-center"
          image="/assets/images/Kitadake-1.jpg"
        />
        <div
          className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-16"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-foreground-700 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </section>

      <section className="relative py-16">
        <div className="container mx-auto px-4">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
            <div className="px-6">
              <div className="flex flex-wrap justify-center">
                <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                  <div className="relative">
                    <img
                      alt=""
                      src="/assets/images/ThangNV8.jpg"
                      className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                  <div className="py-6 px-3 mt-32 sm:mt-0">
                    <button
                      className="bg-gray-700 hover:bg-primary-300 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        setLike(!liked);
                      }}
                    >
                      <Icon
                        icon={liked ? "check-double" : "thumbs-up"}
                        className="mr-3"
                      />
                      いいね
                    </button>
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4 lg:order-1">
                  <div className="flex justify-center py-4 lg:pt-4 pt-8">
                    <div className="mr-4 p-3 text-center">
                      <span className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                        <Icon icon="child" className="mx-1 text-orange-600" />
                        <Icon icon="child" className="mx-1 text-blue-700" />
                        <Icon icon="child" className="mx-1 text-orange-600" />
                      </span>
                      <span className="text-sm text-gray-500">
                        3人の子供の父親
                      </span>
                    </div>
                    <div className="mr-4 p-3 text-center">
                      <span className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                        <Icon icon="location-dot" className="mx-1" />
                      </span>
                      <span className="text-sm text-gray-500">
                        東京都町田市在住
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center mt-12">
                <h3 className="text-2xl font-semibold leading-normal text-gray-800 mb-2">
                  グエン　ヴァン　タン
                </h3>
                <div className="text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold uppercase">
                  (Nguyen Van Thang)
                </div>
                <div className="w-full md:w-6/12 lg:w-4/12 mx-auto text-gray-700 mt-10">
                  <div className="w-full mb-2 mt-10">
                    <Icon
                      icon="cake-candles"
                      className="text-gray-500 mr-2 text-lg"
                    />
                    1984/09/10
                  </div>
                  <div className="mb-2 w-full">
                    <Icon
                      icon="briefcase"
                      className="text-gray-500 mr-2 text-lg"
                    />
                    FPTジャパン
                  </div>
                  <div className="mb-2 w-full">
                    <Icon
                      icon="university"
                      className="text-gray-500 mr-2 text-lg"
                    />
                    東京大学工学部
                  </div>
                  <div className="mb-2 w-full">
                    <Icon
                      icon="graduation-cap"
                      className="text-gray-500 mr-2 text-lg"
                    />
                    FHNW（スイス）MBA
                  </div>
                </div>
              </div>
              <div className="mt-10 py-10 border-t border-gray-300 text-center">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-9/12 px-4">
                    <p className="mb-4 text-lg leading-relaxed text-gray-800">
                      3人の子供の父親。旅行、キャンプ、登山などのアウトドア派でありながら、
                      <br />
                      引きこもってプログラムのコーディングをしたり、物作り（DIY）をしたりするインドア派。
                    </p>
                    <Link
                      href="/thangiswho/profile"
                      className="font-normal text-blue-500"
                    >
                      もっと見る
                      <Icon icon="chevron-right" className="mx-1" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
