import React from "react";

type Props = {
  title?: string;
  content?: string;
  className?: string;
  image: string;
  imageTitle?: string;
  polygon?: boolean;
  polygonColor?: string;
};

export default function Card({
  title,
  content,
  className,
  image,
  imageTitle,
  polygon,
  polygonColor,
}: Props) {
  return (
    <div
      className={`relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg ${className}`}
    >
      <img
        alt={imageTitle || title || "..."}
        src={image}
        className={`w-full align-middle ${
          title || content ? "rounded-t-lg" : "rounded-lg"
        }`}
      />
      <blockquote className="relative">
        {polygon && (
          <svg
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 583 95"
            className="absolute left-0 w-full block h-95-px -top-94-px"
          >
            <polygon
              points="-30,95 583,95 583,65"
              className={`fill-current text-${polygonColor}`}
            ></polygon>
          </svg>
        )}
        {title && <h4 className="text-xl font-bold px-8 py-4">{title}</h4>}
        {content && (
          <p className="text-md font-light mt-2 px-8 pb-8">{content}</p>
        )}
      </blockquote>
    </div>
  );
}
