import React from "react";
import Link from "components/Common/Link";
import Icon from "components/Common/Icon";
import Card from "components/Cards/Card";
import CardImage from "components/Cards/CardImage";
import StockPrice from "components/Stock/Price";
import ServiceYahooCategory from "components/News/ServiceYahooCategory";

export default function About() {
  return (
    <main>
      <div className="relative pb-20 flex content-center items-center justify-center min-h-screen-75">
        <div className="absolute top-0 w-full h-full bg-center bg-cover bg-[url('/assets/images/Kitadake-3.jpg')]">
          <span className="w-full h-full absolute opacity-50 bg-background"></span>
        </div>
        <div className="container -mt-40 relative mx-auto">
          <div className="items-center flex flex-wrap">
            <div className="w-full lg:w-8/12 px-4 mx-auto text-center">
              <div className="">
                <h1 className="text-primary-300 font-semibold text-5xl">
                  Nguyen Van Thang
                </h1>
                <p className="mt-4 text-lg text-foreground-200">
                  3人の子供の父親。旅行、キャンプ、登山などのアウトドア派でありながら、
                  <br />
                  引きこもってプログラムのコーディングをしたり、物作り（DIY）をしたりするインドア派。
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-16"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-foreground-700 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </div>

      <section id="about1" className="pb-10 -mt-40 bg-background-300">
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap">
            <div className="md:w-3/12 lg:pt-20 p-4">
              <CardImage
                image="/assets/images/kids.jpeg"
                title="3人の子供の父親"
                className="bg-background-700 text-foreground-700"
              />
              <CardImage
                image="/assets/images/Fujisan-swing.jpeg"
                imageTitle="3人の子供の父親"
                className="bg-background-700 text-foreground-700"
              />
            </div>
            <div className="md:w-3/12 lg:pt-10 p-4">
              <CardImage
                image="/assets/images/camping.jpg"
                title="アウトだが大好き"
                className="bg-background-700 text-foreground-700"
              />
              <CardImage
                image="/assets/images/trekking.jpg"
                imageTitle="アウトだが大好き"
                className="bg-background-700 text-foreground-700"
              />
            </div>
            <div className="md:w-3/12 lg:-mt-16 p-4">
              <CardImage
                image="/assets/images/diy-2.jpeg"
                title="DIYも熱心"
                className="bg-background-700 text-foreground-700"
              />
              <CardImage
                image="/assets/images/diy-1.jpeg"
                imageTitle="DIYも熱心"
                className="bg-background-700 text-foreground-700"
              />
            </div>
            <div className="md:w-3/12 lg:pt-20 p-4">
              <CardImage
                image="/assets/images/coding-1.jpeg"
                title="やはりプログラミングが最高"
                className="bg-background-700 text-foreground-700 animate-pulse"
              />
            </div>
          </div>
        </div>
      </section>

      <section id="about2" className="relative py-20 bg-background-100">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-foreground-900 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap items-center -mt-10">
            <div className="w-full md:w-7/12 mx-auto px-4">
              <div className="pt-12 md:pt-0 lg:pr-12">
                <h3 className="text-primary-500 text-3xl font-semibold">
                  UIUX scope2 Project
                </h3>
                <p className="mt-4 text-lg leading-relaxed">
                  2022年05月から参加をさせていただいております
                </p>
                <ul className="list-none mt-6">
                  <li className="py-2">
                    <div className="flex items-center">
                      <Icon
                        icon="sitemap"
                        cover={true}
                        className="bg-background-800 text-foreground-700 mr-3"
                      />
                      <h4 className="">技術リーダ</h4>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <Icon
                        icon="certificate"
                        cover={true}
                        className="bg-background-800 text-foreground-700 mr-3"
                      />
                      <h4 className="">Mulesoft認定開発者（MCD）Level 1</h4>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center animate-bounce">
                      <Icon
                        icon="code"
                        cover={true}
                        className="bg-background-800 text-foreground-700 mr-3 animate-ping"
                      />
                      <h4 className="">よろしくお願いいたします</h4>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full md:w-5/12 mr-auto px-4 md:px-0">
              <div className="md:w-1/2">
                <CardImage
                  image="/assets/images/ThangNV8.jpg"
                  imageTitle="Nguyen Van Thang"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-foreground-800 fill-current"
              points="0 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </section>

      <section id="demo1" className="relative pt-6 pb-20  bg-background-200">
        <div className="container mx-auto px-8">
          <div className="w-full pb-10">
            <h3 className="text-primary-500 text-3xl font-semibold">
              デモ１：最新ニュース比較
            </h3>
          </div>
          <div className="items-center flex flex-wrap">
            <div className="w-full pb-10 ">
              <h3 className="w-full text-xl font-semibold ">
                キャンピングカー
              </h3>
              <div className="w-full">
                <ServiceYahooCategory
                  length={4}
                  keyword="キャンピングカー"
                  comment={3}
                />
              </div>
            </div>
          </div>
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-6/12 md:pr-10 h-800-px overflow-hidden">
              <h3 className="w-full text-xl font-semibold ">ANAグループ</h3>
              <div className="w-full">
                <ServiceYahooCategory
                  length={4}
                  keyword="ANAグループ"
                  comment={1}
                />
              </div>
            </div>
            <div className="w-full md:w-6/12 md:pl-10 h-800-px overflow-hidden">
              <h3 className="w-full text-xl font-semibold">JALグループ</h3>
              <div className="w-full">
                <ServiceYahooCategory
                  length={4}
                  keyword="JALグループ"
                  comment={1}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="demo2" className="relative pt-20 pb-40  bg-background-100">
        <div className="container mx-auto px-4 pb-20">
          <div className="w-full pb-10">
            <h3 className="text-primary-500 text-3xl font-semibold">
              デモ２：最新株価
            </h3>
          </div>
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-6/12 lg:w-3/12">
              <div className="w-full px-4">
                <StockPrice code="9202" duration="5y" />
              </div>
            </div>
            <div className="w-full md:w-6/12 lg:w-3/12">
              <div className="w-full px-4">
                <StockPrice code="9201" duration="5y" />
              </div>
            </div>
            <div className="w-full md:w-6/12 lg:w-3/12">
              <div className="w-full px-4">
                <StockPrice code="4739" duration="5y" />
              </div>
            </div>
            <div className="w-full md:w-6/12 lg:w-3/12">
              <div className="w-full px-4">
                <StockPrice code="4414" duration="2y" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="relative block pb-24 bg-background-300">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-center ">
            <div className="w-full lg:w-6/12 px-4 -mt-24">
              <Card
                title="問い合わせ"
                className="bg-background-200 text-foreground-200"
              >
                <form name="contact" data-netlify="true" method="POST">
                  <div className="">
                    <div className="w-full my-1 ">
                      <div className="mb-2">
                        <label className="block uppercase" htmlFor="email">
                          メール
                        </label>
                      </div>
                      <input
                        name="email"
                        type="email"
                        className="px-3 py-3 form-input placeholder-gray-400 bg-gray-100 focus:bg-yellow-100 text-gray-800 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                        placeholder="Your email"
                        required={true}
                        minLength={5}
                        aria-invalid="false"
                      />
                    </div>
                    <div className="w-full my-1 ">
                      <div className="mb-2">
                        <label className="block uppercase" htmlFor="name">
                          名前
                        </label>
                      </div>
                      <input
                        name="name"
                        type="string"
                        className="px-3 py-3 form-input placeholder-gray-400 bg-gray-100 focus:bg-yellow-100 text-gray-800 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                        placeholder="Your name"
                        required={true}
                        minLength={5}
                        aria-invalid="false"
                      />
                    </div>
                    <div className="w-full my-1 ">
                      <div className="mb-2"></div>
                      <input
                        name="form-name"
                        type="hidden"
                        className="px-3 py-3 form-input placeholder-gray-400 bg-gray-100 focus:bg-yellow-100 text-gray-800 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                        placeholder=""
                        aria-invalid="false"
                        value="contact"
                      />
                    </div>
                    <div className="w-full my-1 ">
                      <div className="flex mb-2">
                        <label className="block uppercase" htmlFor="message">
                          メッセージ
                        </label>
                      </div>
                      <textarea
                        name="message"
                        className="px-3 py-3 form-input placeholder-gray-400 bg-gray-100 focus:bg-yellow-100 text-gray-800 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        placeholder="Your message"
                        required={true}
                        rows={5}
                        aria-invalid="false"
                      ></textarea>
                    </div>
                  </div>
                  <div className="w-full text-center mt-6 flex text-sm">
                    <div className="w-full">
                      <div className="w-full text-center">
                        <button
                          className="items-center text-gray-200 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 bg-primary-300 w-full "
                          type="submit"
                        >
                          送信
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
