import React from "react";
import Icon from "components/Common/Icon";

type Props = {
  color: string;
  icon?: string;
  caption: string;
  message: string;
};

export default function Alert({ color, icon, caption, message }: Props) {
  return (
    <>
      <div
        className={`text-white px-6 py-4 border-0 rounded relative mb-4 bg-${color}-500`}
      >
        <span className="text-xl flow mr-5 align-middle">
          <Icon icon={icon ?? "exclamation"} />
        </span>
        <span className="flow align-middle mr-8">
          <b className="capitalize">{caption}</b> {message}
        </span>
      </div>
    </>
  );
}
