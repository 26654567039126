import React from "react";
import Icon from "components/Common/Icon";

type Props = {
  className?: string;
};

export default function ArticlesListLoading({ className }: Props) {
  return (
    <>
      <div
        className={`w-full relative min-w-0 break-words rounded mb-6 xl:mb-0 shadow-lg ${className}`}
      >
        <div className="animate-pulse flex-auto p-4 lg:p-8">
          <div className="flex flex-wrap">
            <div className="relative w-auto flex-initial">
              <div
                className={
                  "text-gray-200 p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full bg-gray-500"
                }
              >
                <Icon icon="bread-slice" />
              </div>
            </div>
            <div className="relative w-full pl-4 max-w-full flex-grow flex-1">
              <span className="flex w-full mb-2 bg-gray-500">&nbsp;</span>
              <h5 className="bg-gray-500 text-xs">&nbsp;</h5>
            </div>
          </div>
          <div className="text-sm mt-4">
            <div className="flex w-full mb-2 bg-gray-500">&nbsp;</div>
            <div className="flex w-full mb-2 bg-gray-500">&nbsp;</div>
            <div className="flex w-full mb-2 bg-gray-500">&nbsp;</div>
            <div className="flex w-full mb-2 bg-gray-500">&nbsp;</div>
            <div className="flex w-full mb-2 bg-gray-500">&nbsp;</div>
          </div>
        </div>
      </div>
    </>
  );
}
