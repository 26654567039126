import React from "react";
import AlertError from "components/Alerts/AlertError";
import { useSWRFetch } from "libs/api";
import Chart from "./Chart";

type Props = {
  code: string;
  duration: string;
  className?: string;
};

type StockPrice = {
  code: string;
  name: string;
  open: number;
  close: number;
  change: number;
  high: number;
  low: number;
  average: number;
  volume: number;
  value: number;
  date: string;
};

export default function Price({ code, duration, className }: Props) {
  const url = `${process.env.REACT_APP_API_PATH_STOCKPRICE}?code=${code}&force=1`;
  const { data: stockPrice, isLoading, error, noData } = useSWRFetch(url);

  if (isLoading)
    return (
      <div className="h-6 w-full p-3 bg-background-500 animate-pulse">...</div>
    );

  if (error || noData || !stockPrice || !stockPrice[code])
    return (
      <div className="flex flex-wrap">
        <div className="w-full p-3">
          <AlertError message={`Could not load the code price #${code}`} />
        </div>
      </div>
    );

  let price: StockPrice = stockPrice[code];
  let textColor = price.change > 0 ? "text-blue-600" : "text-red-600";
  let change =
    price.close > 0 ? ((price.change * 100) / price.close).toFixed() : 0;

  return (
    <div
      className={`relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white text-gray-600 ${className}`}
    >
      <Chart code={code} duration={duration} className="rounded-lg" />
      <blockquote className="w-full p-4">
        <h4 className="w-full">{price.name}</h4>
        <div className={`w-full text-xl py-2 ${textColor}`}>
          <span className="text-4xl pr-4">{price.close}</span> ({price.change}{" "}
          {change}%)
        </div>
        <div className="w-full flex text-sm">
          <div className="w-6/12 pr-6">
            <div className="w-full">
              高 <span className="mx-1">{price.high}</span>
            </div>
            <div className="w-full">
              始 <span className="mx-1">{price.open}</span>
            </div>
          </div>
          <div className="w-6/12 pr-6">
            <div className="w-full">
              安 <span className="mx-1">{price.low}</span>
            </div>
            <div className="w-full">
              株 <span className="mx-1">{price.volume.toLocaleString()}</span>
            </div>
          </div>
        </div>
      </blockquote>
    </div>
  );
}
