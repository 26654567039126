import React from "react";
import { PageProps } from "gatsby";
import { redirect } from "libs/utils";
import { isLoggedIn } from "services/auth";
import Config from "config/site";

type Props = PageProps & {
  component: React.ComponentType<PageProps>;
};

export default function PrivateRoute({
  component: Component,
  location,
  ...rest
}: Props) {
  const { loginURL } = Config;
  if (
    typeof window !== "undefined" &&
    !isLoggedIn() &&
    location.pathname !== loginURL
  ) {
    redirect(loginURL + "?to=" + encodeURIComponent(location.href));
    return null;
  }

  return <Component location={location} {...rest} />;
}
