import React from "react";

import Alert from "./Alert";

type Props = {
  message: string;
};

export default function AlertInfo({ message }: Props) {
  return <Alert color="blue" icon="info" caption="Info" message={message} />;
}
