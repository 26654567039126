import React from "react";

import { Article } from "./types";
import ExternalLink from "components/Common/ExternalLink";

type Props = {
  article: Article;
  internalHref?: string;
  className?: string;
};

export default function ExternalArticleSummary({
  article,
  internalHref,
  className,
}: Props) {
  return (
    <div
      className={`relative mt-4 mb-1 bg-gray-100 text-gray-800 w-full shadow-lg ${className}`}
    >
      <div className="md:flex md:h-36 lg:h-32 overflow-hidden">
        <div className="md:flex-shrink-0">
          <ExternalLink
            href={article.link}
            internalHref={internalHref}
            target="_blank"
          >
            <img
              className="sm:w-full md:w-auto md:h-36 lg:h-32 object-center"
              src={article.image}
              alt={article.title}
            />
          </ExternalLink>
        </div>
        <div className="p-2">
          <ExternalLink
            className="tracking-wide text-xs text-gray-500 font-semibold"
            href={article.link}
          >
            {article.publisher} {article.published_at}
          </ExternalLink>
          <ExternalLink
            href={article.link}
            internalHref={internalHref}
            target="_blank"
            className="block capitalize mt-1 leading-tight font-medium text-indigo-600 hover:text-primary-400"
          >
            {article.title}
          </ExternalLink>
          <div className="mt-2 text-gray-600 lg:text-xs md:text-sm">
            {article.summary}
          </div>
        </div>
      </div>
    </div>
  );
}
