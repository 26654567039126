import React from "react";
import { PageProps } from "gatsby";
import { Router } from "@reach/router";
import Layout from "layouts/EmptyLayout";
import PrivateRoute from "components/Routes/PrivateRoute";
import Route from "components/Routes/Route";
import ThangisWho from "contents/ThangisWho";
import ThangisWhoProfile from "contents/ThangisWhoProfile";

export default function Page(props: PageProps) {
  return (
    <Layout title="Abou ThangisWho">
      <Router basepath="/thangiswho">
        <Route {...props} path="/" component={ThangisWho} />
        <PrivateRoute
          {...props}
          path="/profile"
          component={ThangisWhoProfile}
        />
      </Router>
    </Layout>
  );
}
